import React, { useState, useEffect } from "react"
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import * as style from 'styles/accordion.module.scss';



const Accordion = ({ title, children }) => {
	const defaultHeight = 0;
	const [show, setShow] = useState(false);
	const [contentHeight, setContentHeight] = useState(defaultHeight);

	const [ref, { height }] = useMeasure();

	const springProps = useSpring({
		config: {
			duration: 300,
		},
		from: {
			opacity: 0,
			height: defaultHeight,
		},
		opacity: (show ? 1 : 0),
		height: (show ? contentHeight : defaultHeight),
	});

	useEffect(() => {
		setContentHeight(height);
		window.addEventListener('resize', setContentHeight(height));
		return window.removeEventListener('resize', setContentHeight(height));
	}, [height]);

	return (
		<div className={style.container}>
			<div role="presentation" onClick={() => setShow(!show)} className={`${style.title}`}>{title}<div className={`${style.icon} ${(show ? style.expanded : '')}`}></div></div>
			<animated.div style={springProps}><div ref={ref}>{ children }</div></animated.div>
		</div>
	)
}

export default Accordion