import * as React from "react"

import * as style from 'styles/signup.module.scss';

const Signup = () => {
	return (
		<section id="signup" className={style.signup}>
			<div className="grid-container">
				<div className="grid-margin-x grid-x">
					<div className="small-12 medium-6 cell">
						<div className={style.emailHeader}>In the Know</div>
						<p>Stay up to date on the latest news, details and developments involving Walton County’s beach renourishment program.</p>
						<iframe src="/form.html" title="Email signup" frameBorder="0" width="100%" className={style.frame} />
						{ /* 
						<form>
							<input type="text" name="email" placeholder="Email Address" aria-label="Email Address"/><input type="submit" value="Subscribe" />
						</form>
						*/ }
					</div>
				</div>
			</div>
		</section>
	)
}

export default Signup