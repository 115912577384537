import * as React from "react"

import Accordion from 'components/accordion';

import * as style from 'styles/faq.module.scss';

import figure1 from 'images/Figure1.png';
import figure2 from 'images/Figure2.png';

const FAQ = () => {
	return (
		<section id="faq" className={style.faq}>
			<div className="grid-container">
				<div className="grid-margin-x grid-x">
					<div className="small-12 medium-offset-1 medium-10 cell">
						<div className={style.title}>Frequently Asked Questions</div>
						
						<div className={style.category}>Overview</div>
						<Accordion title="What is Friends of the Beach?">
							<p>Friends of the Beach is a Hurricane Storm Damage Reduction (HSDR) initiative in Walton County, Florida. HSDR bolsters the coastline against storms in order to safeguard the dune system, upland structures, and economy. Overseen by the U.S. Army Corps of Engineers, the beach nourishment process takes both proactive and responsive measures, assessing and replenishing sand as needed to preserve crucial elevation. Sand is placed at the toe of existing dunes and on flat sandy areas of the beach to increase the elevation to five and a half feet above sea level, creating sacrificial dunes. In the event of storm destruction, the Federal government will rebuild and replenish the coastline back to its pre-storm state.</p>
						</Accordion>
						<Accordion title="How is it funded?">
							<p>Friends of the Beach is funded exclusively by the Tourist Development Tax (TDT) collected on visitor accommodations in Walton County. The third penny of the TDT is designated for beach nourishment and maintenance.</p>
						</Accordion>
						<div className={style.separator}></div>

						<div className={style.category}>Sand</div>
						<Accordion title="Where will the sand come from?">
							<p>The selected borrow area lies about five miles offshore western Walton County in water depths of approximately 70 to 80 ft. The borrow area is approximately 10,200 ft (1.9 miles) long, 5,300 ft (1.00 miles) wide, and encompasses 1,558 acres [1].</p>
							<p>Engineers identified and selected the borrow area(s) using geophysical and geotechnical sampling and analysis on regional and detailed scales. The selected borrow area offshore of western Walton County is centrally located and contains the highest quality and most suitable borrow material identified by the reconnaissance and detailed sand source investigations. The borrow area’s limits are designed to use sand specifically identified by these studies to meet the goals of the project and State, Federal, and local requirements for beach compatibility [1].</p>
						</Accordion>
						<Accordion title="Will it match the existing beach in color, texture and grain size?">
							<p>The wet sand to be dredged generally has a moist Munsell Color Classification lighter than or equal to 5Y 7/2 (light gray, HEX code #B9AD94) (Figure 1). [1]</p>
							<p><img src={figure1} alt="Figure 1 5Y 7/2 Color Swatch (Borrow Area)" /></p>
							<p>A soil’s (e.g., sand) texture is dependent on distributions of soil type (e.g., sand, clay, silt), and particle grain size. The borrow area material is characterized by fine to medium quartz sand. The mean grain size of the borrow area sand is 1.70 phi (0.30 mm) with standard deviation of 0.41 phi [2, 1]. </p>
						</Accordion>
						<Accordion title="How is it different from “upland” sand?">
							<p>The “upland” (i.e., native beach) and offshore sands within the proposed borrow area are quite similar. The Corps provides the following statement in their General Investigations Study that is helpful to understand the context of the sand differences.</p>
							<p>“Based on the extensive geotechnical investigations, the proposed borrow site has been demonstrated to be the most suitable source, and has sand color, size, and composition generally similar to that of the native beach” [1]. </p>
							<p>The wet sand to be dredged generally has a moist/wetted Munsell Color Classification lighter than or equal to 5Y 7/2 (light gray, HEX code #B9AD94) (Figure 1). In comparison, the native beach samples in moist/wetted condition appear to be Munsell color 5Y 8/1 (white, HEX code #CEC7BB) (Figure 2). [1] </p>
							<p><img src={figure2} alt="Figure 2 5Y 8/1 Color Swatch (Native Beach)" /></p>
							<p>The Corps addresses the aesthetics of the sand color in the following excerpt, which is helpful to understand color variation immediately following dredging of sand from offshore. </p>
							<p>“Some discoloration of the sand would occur following placement due to the fact that the sands to be placed on the beach are coming from anaerobic environment. Natural bleaching of the sand should occur within one to two months. Rainfall and wave action would act to filter out the fine grained materials from the restored beaches and increase the compatibility of the nourishment sands with those presently on the beach. These impacts will be temporary and insignificant in nature” [1].</p>
							<p>The native beach and borrow area are characterized by fine to medium quartz sand [2]. The grainsize distributions are also similar; in summary of engineering evaluations, the weighted average of the grain size statistics yield a composite mean grain size of 1.69 phi (0.30 mm) with standard deviation of 0.55 phi. For comparison, the native beach has a mean grain size of 1.70 phi (0.30 mm) and standard deviation of 0.41 phi [3].</p>
						</Accordion>
						<Accordion title="Is the sand compatible with our existing beaches?">
							<p>Engineers used geophysical and geotechnical sampling and analysis on regional and detailed scales to clearly identify a borrow area with sand characteristics that best matched geotechnical sampling of the native Walton County beach. This research effort was extensive and is documented in a Sand Source Investigation Report [4]. The Corps provides the following statement in their General Investigations Study that is helpful to understand the scope of the research effort. </p>
							<p>“Based on the extensive geotechnical investigations, the proposed borrow site has been demonstrated to be the most suitable source, and has sand color, size, and composition generally similar to that of the native beach” [3]. </p>
							<p>The selected borrow area offshore western Walton County is centrally located and contains the highest quality and most suitable borrow material identified by these reconnaissance and detailed sand source investigations. The study reviewed approximately 64 square miles of offshore area for geophysical compatibility in the reconnaissance phase. These results informed a detailed geotechnical sampling phase, which sampled over 50 vibracores (20 ft deep) from the borrow area for lab analysis [4]. Along the native beach, samples were collected every one mile at the dune vegetation, dune toe, mid-berm, MHW shoreline and MLW shoreline positions. The borrow area’s limits are designed to use sand specifically identified by these studies to meet the goals of the project and State, Federal, and local requirements for beach compatibility [1]. </p>
							<p>To summarize, engineers reviewed color, soil classification, grain size distribution, and shell content for sand compatibility between the borrow area design specifications and the native beach. The color of the borrow area acceptable color limit is described as Munsell color 5Y 7/2 or lighter for moist/wetted material while the native sand is described as Munsell color 5y 8/1 or lighter for moist/wetted material. The acceptable silt content for the borrow material is less than 2.5% and the native beach silt content is less than 0.3%. The acceptable borrow area carbonate content is required to be less than 5% and the native beach sand is less than 1.2%. The acceptable borrow area mean grain size range as the native beach mean grain size range of 0.24-0.48 mm [4]. A Sand Quality Control and Quality Assurance Plan requires the construction contractor to meet these thresholds and operations would be altered if sand placed on the beach is out of compliance [2]. </p>
							<p>The Corps provides context to how dredged materials will increase in compatibility with the native beach over time. </p>
							<p>“Some discoloration of the sand would occur following placement due to the fact that the sands to be placed on the beach are coming from anaerobic environment. Natural bleaching of the sand should occur within one to two months. Rainfall and wave action would act to filter out the fine grained materials from the restored beaches and increase the compatibility of the nourishment sands with those presently on the beach. These impacts will be temporary and insignificant in nature” [1]. </p>
						</Accordion>
						<Accordion title="Will it be shell-free?">
							<p>Placement of sand on the beach will follow a Sand Quality Control and Quality Assurance Plan to make sure sand placed on the beach meets specific requirements that include a maximum shell content (5%). Shells over 3/4” are unacceptable. Screening of finer shells will not likely be required to meet regulatory compliance conditions. However, shell screening could be performed using specialized equipment at an additional cost to the County. </p>
							<p>The native beach sand contains predominantly quartzitic sand with minimal shell (carbonates). The maximum percentage of calcium carbonate (shells) is 1.24% (MLW at R-115), the minimum is 0%, and the average is 0.23%. The borrow site material is required to contained less than 5% shell content [4]. Unacceptable materials including debris, trash, and rocks or rubble larger than three-fourths (3/4) inch in diameter will be removed dredged material [2]. </p>
						</Accordion>
						<div className={style.separator}></div>

						<div className={style.category}>Environment</div>
						<Accordion title="Has an Environmental Impact Study been conducted?">
							<p>The Corps completed an Environmental Assessment for the project with a Finding of No Significant Impact (FONSI). An Environmental Assessment that results in a FONSI does not typically require an Environmental Impact Study (EIS), as the FONSI itself satisfies the requirements of the National Environmental Policy Act. </p>
						</Accordion>
						<Accordion title="Will the project impact coastal dune lakes?">
							<p>No, the project will avoid impact to costal dune lake outfalls. The Corps directly addresses the potential for effect to these rare systems in the General Investigations Study.</p>
							<p>“To avoid impacts to the natural dune lake breaching process, construction of the selected plan does not include placement of dunes or berm in front of the coastal lake outfalls. Restoring a beach-dune system in the areas adjacent to the dune lake resources will provide for continued sustainability to the fragile ecosystems of the lakes. Beach placement design will be such as to not increase berm elevations in the immediate vicinity of the dune lake outfalls” [1].</p>
						</Accordion>
						<div className={style.separator}></div>

						<div className={style.category}>Property Rights</div>
						<Accordion title="How old is the Mean High Water Line Survey?">
							<p>The latest MHWL survey was completed by FGS in May 2019. The MHWL is accessible through the County’s GIS Portal at <a href="https://www.co.walton.fl.us/1096/County-Maps" target="_blank" rel="noreferrer">https://www.co.walton.fl.us/1096/County-Maps</a>. Please follow instructions below.</p>
							<ul>
								<li>Click on General Interactive Map</li>
								<li>Navigate to the Layer List in the top right hand corner of the interactive map</li>
								<li>Select the Walton County Mean High Water Line Designation 2019 checkbox</li>
							</ul>
						</Accordion>
						<Accordion title="What property rights are given up or gained by the establishment of an Erosion Control Line (ECL)?">
							<p>Generally speaking, the seafloor offshore of a beach is owned by the State. When a project places beach fill atop seafloor to restore dry beach, these state lands are impacted (decreased) and upland property increases. To “draw a line in the sand” between restored beach (upland property) and state lands, the pre-construction Mean High Water line is established as an ECL. </p>
							<p>Florida Statutes section 161.141 best describe the establishment of ECLs and their relation to property rights. A relevant excerpt follows: </p>
							<p>“Prior to construction of such a beach restoration project, the board of trustees must establish the line of mean high water for the area to be restored; and any additions to the upland property landward of the established line of mean high water which result from the restoration project remain the property of the upland owner subject to all governmental regulations and are not to be used to justify increased density or the relocation of the coastal construction control line as may be in effect for such upland property. The resulting additions to upland property are also subject to a public easement for traditional uses of the sandy beach consistent with uses that would have been allowed prior to the need for the restoration project. It is further declared that there is no intention on the part of the state to extend its claims to lands not already held by it or to deprive any upland or submerged landowner of the legitimate and constitutional use and enjoyment of his or her property” [5]. </p>
						</Accordion>
						<div className={style.separator}></div>

						<div className={style.category}>Financial</div>
						<Accordion title="Is HSDR funded by Congress?">
							<p>The funding mechanism for federal cost share for construction is established by the Water Resources Reform and Development Act (WRRDA) bill of 2014 for the construction phase cost of the project [1]. </p>
						</Accordion>
						<Accordion title="Will HSDR impact insurance rates?">
							<p>The County cannot speculate about individual homeowner insurance rates and cannot predict future storm impacts. However, the primary purpose of the project is to reduce the risk of storm damage to beachfront properties and structures. Should the project be constructed, significant and long-term funding commitments from the federal government will also be available to maintain and restore the beach following severe storm impacts.</p>
						</Accordion>
						<div className={style.separator}></div>

						<div className={style.category}>Construction</div>
						<Accordion title="During what times will construction occur?">
							<p>Construction is restricted to daylight hours only.</p>
						</Accordion>
						<Accordion title="How noisy will it be?">
							<p>The Corps addresses the potential for noise in the General Investigation Study:</p>
							<p>“Noise from the dredge and other associated support equipment would be evident in the project area. Noise levels would be typical of what is already commonly accepted and occurring at the Corps’ dredging operation sites. While this noise would be evident to those workers on the job, residents, and by-standers in close proximity of the project, it would be short-term and insignificant. No long-term increase in noise would occur in or around the project area. Normal noise levels would be achieved at the end of the construction period” [1].</p>
						</Accordion>
						<Accordion title="How many feet per day will be completed?">
							<p>Generally, the Corps anticipates construction of the entire county-wide project to occur over a 6-9 month period. Construction sequencing within individual project reaches is difficult to predict and some properties may have contractor activity longer than others. Using total project length of approximately 13.5 miles and a construction period of 6–9 months would yield approximately 300 linear feet constructed per day.</p>
						</Accordion>
					</div>
				</div>
			</div>
		</section>
	)
}

export default FAQ