import * as React from "react"

import * as style from 'styles/footer.module.scss';

const Footer = () => {
	return (
		<div className="footerWrapper">
			<footer>
				<div className="grid-container">
					<div className="grid-margin-x grid-x">
						<div className="small-12 cell">
							<div className={style.headline}>References</div>
							<ol>
								<li>United States Army Corps of Engineers, "Walton County, Florida, Hurricane and Storm Damage Reduction General Investigations Study, FONSI and Environmental Assessment," 2012.</li>
								<li>Taylor Engineering, Inc., "Sand Quality Control and Quality Assurance Plan, Walton County 30A Corridor Beach Restoration Project," 2007.</li>
								<li>Taylor Engineering, Inc., "Coastal Engineering Narrative, Walton County 30A Corridor Beach Restoration Project," 2007.</li>
								<li>Taylor Engineering, Inc., "Sand Source Investigation, Walton County, Florida," 2008.</li>
								<li>Florida Department of Environmental Protection, "Erosion Control Line Information," 2019.</li> 
							</ol>
						</div>
					</div>
				</div>
			</footer>
			<div className={style.bottomFooter}>
				<div className="grid-container">
					<div className="grid-margin-x grid-x">
						<div className="small-12 cell">
							<p>Initiative supported by Walton County, Florida.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer

