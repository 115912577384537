import React, { useState, useEffect } from "react"
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import * as style from 'styles/header.module.scss';
import logo from 'images/friends.png';

const Header = () => {
	const [collapsed, setCollapsed] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const currentY = window.scrollY;
			if (currentY >= 300)setCollapsed(true);
			else setCollapsed(false);
		};

		window.addEventListener('scroll', handleScroll, {passive: true});

		return () => window.removeEventListener('scroll', handleScroll);
	}, [collapsed]);

	return (
		<>
		<header className={`${collapsed ? style.collapsed : ''}`}>
			<div className="grid-container">
				<div className="grid-margin-x grid-x">
					<div className="small-12 cell">
						<div className={style.logoContainer}>
							<img src={logo} alt="Friends of the Beach" />
						</div>
						<nav>
							<Link to="/">Overview</Link>
							<AnchorLink to="#mission">Our Mission</AnchorLink>
							<AnchorLink to="#signup">Stay in Touch</AnchorLink>
							<AnchorLink to="#faq">FAQ</AnchorLink>
						</nav>
					</div>
				</div>
			</div>
		</header>
		<div className={`${style.spacer}`}></div>
		</>
	)
}

export default Header