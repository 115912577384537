import * as React from "react"

import * as style from 'styles/masthead.module.scss';

const Masthead = () => {
	return (
		<section className={style.masthead}>
			<div className="grid-container" style={{height: '100%'}}>
				<div className="grid-margin-x grid-x" style={{height: '100%'}}>
					<div className="small-12 medium-6 cell" style={{height: '100%'}}>
						<div className={style.content}>
							<div className={style.contentBackground}>
								<h1>Protect + Preserve</h1>
								<p>Storms are inevitable. Losing our beaches isn’t. No matter what storms come, beach nourishment protects the coastline we love and preserves it for generations to come.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Masthead