import * as React from "react"
import Helmet from "react-helmet"

import Header from 'components/header';
import Masthead from 'components/masthead';
import Signup from 'components/signup';
import Faq from 'components/faq';
import Footer from 'components/footer';

import shareImage from 'images/friend-of-the-beach-share.jpg';
import before from 'images/seaside-2005-pre-dennis.jpg';
import after from 'images/seaside-2010-post-dennis.jpg';

import 'styles/app.scss';

// markup
const IndexPage = () => {
	const siteTitle = 'Walton County | Hurricane Storm Damage Reduction';
	const description = 'Learn about how beach nourishment can protect the Walton County coastline through Hurricane Storm Damage Reduction.';
	const siteURL = 'https://www.friendsofthebeachfl.com';
	return (
		<>
		<Helmet>
			<title>{siteTitle}</title>
			<meta name="description" content={description}/>
			<meta property="og:title" content={siteTitle}/>
			<meta property="og:description" content={description}/>
			<meta property="og:image" content={`${siteURL}${shareImage}`} />
			<meta property="og:type" content="website"/>
			<meta property="og:url" content={siteURL}/>
			<meta name="twitter:title" content={siteTitle}/>
			<meta name="twitter:description" content={description}/>
			<meta name="twitter:image" content={`${siteURL}${shareImage}`} />
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>
		<Header/>
		<Masthead />
		<main id="mission">
			<div className="grid-container">
				<div className="grid-margin-x grid-x">
					<div className="small-12 medium-6 cell">
						<div className="header-spacer"></div>
						<h2>Hurricane Storm Damage Reduction</h2>
						<p>Any hurricane has the potential to cause catastrophic damage. Beach nourishment through Friends of the Beach replenishes sand, bolstering the coastline against storms and reconstructing it whenever necessary. Through both proactive and responsive measures, it safeguards our dune system, upland structures and economy.</p>
					</div>
					<div className="small-12 medium-offset-1 medium-5 cell">
						<figure><img src={before} alt="Seaside 2005 (pre Dennis)" /><figcaption>Seaside 2005 (pre Dennis)</figcaption></figure>
						<figure><img src={after} alt="Seaside 2010 (post Dennis)" /><figcaption>Seaside 2010 (post Dennis)</figcaption></figure>
					</div>
				</div>
			</div>
		</main>
		<Signup />
		<Faq />
		<Footer />
		</>
	)
}

export default IndexPage